@import url('./fonts-styleSheet.css');
@import url('./variable.css');

* {
  box-sizing: border-box;
  background-color: var(--primary-1);
  font-family: var(--poppins-Regular);
  margin: 0;
  color: var(--primary-2);
  
}

.App {
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
  
}

.App .design{
display: flex;
flex-direction: column;
width: 100%;

}

.App .searchPhotos{
  margin-top: 20px;
}

.container {
  margin: 33px auto;
  max-width: 1000px;
  padding: 40px  40px 20px 40px;
  border: 2px dashed var(--secondary-2);
  border-radius: 10px;
  
}



.title {
  font-size: var(--fs-4);
}


.form {
  display: grid;
}


.label {
  font-size: var(--fs-3);
  margin-bottom: 1rem;
}

.input {
  font-size: var(--fs-17);
  padding: 8px 21px;
  line-height: 1.8rem;
  border-radius: 20px;
  background-color: var(--primary-1);
}


.button {
  background-color: var( --secondary-11);
  color: var(--primary-1);
  padding: 1rem 2rem;
  border: 1px solid var( --secondary-11);
  border-radius: 20px;
  font-size: var(--fs-1);
  cursor: pointer;
  transition: background-color 250ms;
}


.button:hover {
  background-color: var( --secondary-12);
}


.card-list {
  column-count: 1;
}



.card {
  margin-bottom: 1rem;
  display: flex;
}

.card--image {
  flex: 100%;
  margin-top: 1rem;
  border-radius: 10px;
}

.generated-image{
  display: flex;
  justify-content: center;
  width: 100%;
}
.generated-image > img{
  flex: 0 1 auto;
  border-radius: 10px;
  box-shadow: 8px 8px 8px var(--secondary-3);
  height: auto;
}


.spinner-overlay {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  position: absolute;
  left: 50%;
  top: 230px;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border-top: 16px solid var(--secondary-1);
  border-right: 16px solid var(--secondary-5);
  border-bottom: 16px solid var(--secondary-6);
  border-left: 16px solid var(--secondary-7);
  border-top-color: var(--secondary-1);
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}


/* spinner css end region */
.downloadBtn{
  margin: 43px 0px 0px 0px;
  display: flex;
  justify-content: center;

}

.downloadBtn .btn-12{
  background: var(--secondary-8);
  background: linear-gradient(0deg, var(--secondary-9) 0%, var(--secondary-10) 100%);
  color: var(--primary-1);
    display: block;
    /* position: absolute; */
    width: 130px;
    height: 40px;
    box-shadow:inset 2px 2px 2px 0px var(--secondary-14),
     7px 7px 20px 0px var(--secondary-13),
     4px 4px 5px 0px var(--secondary-13);
    border-radius: 5px;
    margin:0;
    text-align: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all .3s;
    transition: all .3s;
}


/* text animation start region */
.designIam {
  font: normal 0px/50px var(--poppins-SemiBold);
  color: var(--secondary-22);
  font-family: var(--poppins-SemiBold);
}
.designIam p {
  height: 50px;
  float: left;
  margin-right: 0.3em;
  font-size: var(--fs-42);
  color: var(--primary-2);
  font-family: var(--poppins-SemiBold);
  margin-block-end: 0px;
  margin-block-start: 0px;
}
.designIam b {
  float: left;
  overflow: hidden;
  position: relative;
  height: 50px;
  top: 0px;
}
.designIam .innerIam {
  display: inline-block;
  color: var(--secondary-1);
  position: relative;
  white-space: nowrap;
  top: 0;
  left: 0;
  font-size: var(--fs-42);


/*animation*/
-webkit-animation:move 5s;
   -moz-animation:move 5s;
    -ms-animation:move 5s;
     -o-animation:move 5s;
        animation:move 5s;
/*animation-iteration-count*/
-webkit-animation-iteration-count:infinite;
   -moz-animation-iteration-count:infinite;
    -ms-animation-iteration-count:infinite;
     -o-animation-iteration-count:infinite;
        animation-iteration-count:infinite;
/*animation-delay*/
-webkit-animation-delay:1s;
   -moz-animation-delay:1s;
    -ms-animation-delay:1s;
     -o-animation-delay:1s;
        animation-delay:1s;
}
@keyframes move{
0%  { top: 0px; }
20% { top: -50px; }
40% { top: -100px; }
60% { top: -150px; }
80% { top: -200px; }
}

@-webkit-keyframes move {
    0%  { top: 0px; }
    20% { top: -50px; }
    40% { top: -100px; }
    60% { top: -150px; }
    80% { top: -200px; }
}
@-moz-keyframes move {
    0%  { top: 0px; }
    20% { top: -50px; }
    40% { top: -100px; }
    60% { top: -150px; }
    80% { top: -200px; }
}
@-o-keyframes move {
    0%  { top: 0px; }
    20% { top: -50px; }
    40% { top: -100px; }
    60% { top: -150px; }
    80% { top: -200px; }
}
@keyframes move {
    0%  { top: 0px; }
    20% { top: -50px; }
    40% { top: -100px; }
    60% { top: -150px; }
    80% { top: -200px; }
}
/* text animation end region */

/* navigation css start region */
a {
  text-decoration: none;
  color: var(--secondary-15);
}

.imageBanner > img{
  width: 100%;
}

.mainNavContainer{
  position: sticky;
  top: 0;
  left: auto;
  z-index: 1;
}
.site-header { 
  border-bottom: 1px solid var(--primary-1);
  padding: 1em;
  box-shadow: 0px 1px 10px 0px var(--secondary-3);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
}

.site-header::after {
  content: "";
  display: table;
  clear: both;
}

.site-identity {
  float: left;
}

.site-identity h1 {
  font-size: var(--fs-5);
  margin: .7em 0 .3em 0;
  display: inline-block;
}

.site-identity img {
  max-width: 270px;
  float: left;
  margin: 0 10px 0 0;
  cursor: pointer;
}

.site-navigation {
  float: right;
}

.site-navigation ul, li {
  margin: 0; 
  padding: 0;
}

.site-navigation li {
  display: inline-block;
  margin: 1.4em 1em 1em 1em;
}

.site-navigation ul > li > a > img {
  background-color: var(--primary-2);
  padding: 4px;
}

.site-header .site-navigation {
  position: absolute;
  right: 0;
}

/* navigation css end region */


/* button css start region */
.btn-12{
  position: relative;
  bottom: 20px;
  border:none;
  box-shadow: none;
  width: 130px;
  height: 40px;
  line-height: 42px;
  -webkit-perspective: 230px;
  perspective: 230px;
}
.btn-12 span {
  background: var(--secondary-16);
  background: linear-gradient(0deg, var(--secondary-17) 0%, var(--secondary-18) 100%);
color: var(--primary-1);
  display: block;
  position: absolute;
  width: 130px;
  height: 40px;
  box-shadow:inset 2px 2px 2px 0px var(--secondary-19),
   7px 7px 20px 0px var(--secondary-13),
   4px 4px 5px 0px var(--secondary-13);
  border-radius: 5px;
  margin:0;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all .3s;
  transition: all .3s;
}
.btn-12 span:nth-child(1) {
  box-shadow:
   -7px -7px 20px 0px var(--secondary-20),
   -4px -4px 5px 0px var(--secondary-20),
   7px 7px 20px 0px var(--secondary-21),
   4px 4px 5px 0px var(--secondary-22);
  -webkit-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-transform-origin: 50% 50% -20px;
  -moz-transform-origin: 50% 50% -20px;
  transform-origin: 50% 50% -20px;
}
.btn-12 span:nth-child(2) {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  transform: rotateX(0deg);
  -webkit-transform-origin: 50% 50% -20px;
  -moz-transform-origin: 50% 50% -20px;
  transform-origin: 50% 50% -20px;
}
.btn-12:hover span:nth-child(1) {
  box-shadow:inset 2px 2px 2px 0px var( --secondary-14),
   7px 7px 20px 0px var(--secondary-13),
   4px 4px 5px 0px var(--secondary-13);
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
.btn-12:hover span:nth-child(2) {
  box-shadow:inset 2px 2px 2px 0px var( --secondary-14),
   7px 7px 20px 0px var(--secondary-13),
   4px 4px 5px 0px var(--secondary-13);
 color: transparent;
  -webkit-transform: rotateX(-90deg);
  -moz-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
}
/* button css end region */

/* scroll css start region */
::-webkit-scrollbar {
  width: 5px; /* Set the width of the scrollbar */
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background-color: var(--secondary-3);
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary-2);
}


/* scroll css end region */
@media (min-width: 768px) {
  .form {
    grid-template-columns: auto 1fr auto;
    grid-gap: 1rem;
    align-items: center;
  }
  .input {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 600px) {
    .card-list {
        column-count: 1;
    }
}


@media (max-width:767px){
  .designIam p{
    font-size: var(--fs-24);
  }
  .designIam .innerIam{
    font-size: var(--fs-24);
  }
  .generated-image > img{
    width: 100%;
    margin-top: 43px;
  }
  .custom-btn{
    bottom: 0px;
  }
  .main-form-control .form > label{
    display: none;
  }
  .container{
    padding: 20px 15px 40px;
    margin: 17px;
  }
}

@media (min-width:577px) and (max-width:767px){
  .main-form-control .form .custom-btn{
    bottom: 0px;
  }
}


@media (max-width:429px){
  .site-header .site-navigation{
    position: unset;

  }

  .site-navigation li{
    margin: 13px;
    height: 0px;
  }

  .site-header{
    padding: 12px 18px 10px;
    display: flex;
    justify-content: center;
  }
  .container{
    padding: 9px 15px 40px;
    margin: 16px;
  }
}


@media (min-width:768px) and (max-width:1024px){
  .container{
    margin: 20px;
  }
}


@media (min-width:768px) and (max-width:803px){
  .designIam p{
    font-size: var(--fs-32);
  }
  .designIam .innerIam{
    font-size: var(--fs-32);
  }
}

@media (max-width:576px){
  .designIam{
    display: flex;
    flex-direction:column;
    text-align: center;
  }
  .btn-12{
    margin: 0px auto;
  }
}