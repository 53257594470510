:root{
    --primary-1:#fff;
    --primary-2:#11110d;
    --secondary-1:#00A1F1;
    --secondary-2:lightblue;
    --secondary-3:lightgray;
    --secondary-5:#febd03;
    --secondary-6:#7eba01;
    --secondary-7: #f55616;
    --secondary-8:rgb(0,172,238);
    --secondary-9:rgba(0,172,238,1);
    --secondary-10:rgba(2,126,251,1);
    --secondary-11:rgba(0, 0, 0, 0.75);
    --secondary-12:rgba(0, 0, 0, 0.85);
    --secondary-13:rgba(0,0,0,.1);
    --secondary-14:rgba(255,255,255,.5);
    --secondary-15:#000;
    --secondary-16:rgb(0,172,238);
    --secondary-17:rgba(0,172,238,1);
    --secondary-18:rgba(2,126,251,1);
    --secondary-29:#fff9;
    --secondary-20:#0002;
    --secondary-21:#0001;
    --secondary-22:#999;
    
    /* fonts family*/
    --poppins-Regular:'Poppins-regular';
    --poppins-SemiBold:'Poppins-semi-bold';
    
    /* font-sizes */
    --fs-4:4.4rem;
    --fs-3:3rem;
    --fs-17:17px;
    --fs-1:1.4rem;
    --fs-42:42px;
    --fs-5:1.5rem;
    --fs-24:24px;
    --fs-32:32px;
  
  }


  